import { Component, Input, OnDestroy } from '@angular/core';
import { from, Observable, Subject, switchMap } from 'rxjs';
import { ModalController, ToastController } from '@ionic/angular';
import { FacturationService } from '../../../services/facturation.service';
import { FactureService } from '../../../services/facture.service';
import { Action, Context, TypeLog } from '../../../../../../models/log.model';
import { LogService } from '@core/services/log/log.service';

@Component({
  selector: 'envoi-facture',
  templateUrl: 'envoi-facture.component.html',
  styleUrls: ['envoi-facture.component.scss'],
})
export class EnvoiFactureComponent implements OnDestroy {
  private readonly destroy$: Subject<void>;
  @Input() email!: string;
  @Input() idFacturation?: string;
  @Input() idFacture!: string;
  @Input() title!: string;
  loadingSend = false;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly toastController: ToastController,
    private readonly facturationService: FacturationService,
    private readonly factureService: FactureService,
    private readonly logService: LogService,
  ) {
    this.destroy$ = new Subject<void>();
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  send() {
    this.loadingSend = true;
    let sendObs$: Observable<void>;
    if (this.idFacturation) {
      sendObs$ = this.facturationService.envoyerFactureParMail(this.idFacturation, this.email);

      this.logService.log(Context.RDV_FACTURE, Action.ENVOI, TypeLog.INFO, {
        idFacturation: this.idFacturation,
        mail: this.email,
      });
    } else {
      sendObs$ = this.factureService.envoyerFactureParMail(this.idFacture, this.email);

      this.logService.log(Context.RDV_FACTURE, Action.ENVOI, TypeLog.INFO, {
        idFacture: this.idFacture,
        mail: this.email,
      });
    }

    sendObs$
      .pipe(
        switchMap(() =>
          from(
            this.toastController.create({
              header: 'Facture envoyée',
              duration: 5000,
              message: 'Un mail a été envoyé à : ' + this.email,
              position: 'bottom',
            }),
          ),
        ),
        switchMap(toast => toast.present()),
      )
      .subscribe(() => (this.loadingSend = false));
  }

  download() {
    this.factureService.getFacturePDF(this.idFacture).subscribe(pdf => {
      const blob = new Blob([pdf], { type: 'application/pdf' });
      const fileURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = 'facture_' + this.idFacture + '.pdf';
      link.click();
    });
  }

  setMail($event: any) {
    this.email = $event.target.value;
  }
}
