import { Injectable } from '@angular/core';
import { MotifSeparation } from 'app/models/animal.model';
import { Groupe, Rappel, RappelRequest, User, UserResponse, UserWithDeviceId, UtilisateurPreferences } from 'app/models/user.model';
import { firstValueFrom, map, Observable, of, switchMap } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { HttpApiService } from '../http-api/http-api.service';
import { catchError } from 'rxjs/operators';
import { LoginData } from '../../../../models/login-data.model';
import { Client, StructureVeterinaire } from '../../../../models/rdv.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private readonly httpApiService: HttpApiService,
    private readonly authenticationService: AuthenticationService,
  ) {}

  public async getStructureFromClient(structures: StructureVeterinaire[], client?: Client) {
    if (client?.adresse?.codePostal) {
      const structureId: string = await firstValueFrom(this.findStructureIdByCodePostal(client?.adresse?.codePostal));
      if (structureId) {
        return structures?.find(s => s.id === structureId);
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  getCurrentUserWithDeviceId(): Observable<UserWithDeviceId> {
    return this.httpApiService.get<UserResponse>('api/user/me').pipe(
      map(userResponse => userResponse.user),
      switchMap(user =>
        this.authenticationService.getDeviceId(user).pipe(
          catchError(() => of('')),
          map(deviceId => ({ user, deviceId })),
        ),
      ),
    );
  }

  create(asv: any) {
    return this.httpApiService.post(`api/back_office/user/create`, asv);
  }

  // User Animals

  getGroupes(login: LoginData): Observable<Groupe[]> {
    return this.httpApiService.post<Groupe[]>('api/user/groupes', login);
  }

  updateUserPreferences(preferences: UtilisateurPreferences): Observable<UtilisateurPreferences> {
    return this.httpApiService.post<UtilisateurPreferences>('api/user/update-preferences', preferences);
  }

  getAsvList() {
    return this.httpApiService.get<User[]>(`api/back_office/user/allasv`);
  }

  updateAsvPassword(id: string, password: string) {
    return this.httpApiService.post(`api/back_office/user/asv/${id}/password`, password);
  }

  changeUserStatus(id: string, actif: boolean) {
    return this.httpApiService.get(`api/back_office/user/changestatus/${id}/${actif}`);
  }

  getAsv(idAsv: any) {
    return this.httpApiService.get<User>(`api/back_office/user/asv/${idAsv}`);
  }

  updateAsv(asv: User) {
    return this.httpApiService.post<User>(`api/back_office/user/asv/update`, asv);
  }

  findBackofficeUserByEmail(email: string) {
    return this.httpApiService.get<User[]>(`api/back_office/user/findByEmail?email=${email}`);
  }

  findClientByEmail(email: string) {
    return this.httpApiService.get<User>(`api/user/findByEMail?email=${email}`);
  }

  changePassword(codeVerification: string, pass: string) {
    return this.httpApiService.post<void>(`api/back_office/user/changePassword`, { codeVerification, newPassword: pass });
  }

  updateUserForce(user: User) {
    return this.httpApiService.post<User>(`api/user/update-force`, user);
  }

  countDoublonsClient(nom: string, prenom: string): Observable<number> {
    return this.httpApiService.get<number>(`api/back_office/user/doublons/count/${nom}/${prenom}`);
  }

  findStructureIdByCodePostal(codePostal: string) {
    return this.httpApiService.get<string>(`api/back_office/structure/code-postal/${codePostal}`);
  }

  findMotifSeparation(): Observable<MotifSeparation[]> {
    return this.httpApiService.get<MotifSeparation[]>(`api/motifSeparation`);
  }

  asPaiementsEnAttente(email: string): Observable<boolean> {
    return this.httpApiService.get(`api/rdvDomicile/asPaiementsEnAttente/${email}`);
  }

  saveRappel(rappel: Rappel): Observable<Rappel> {
    return this.httpApiService.post<Rappel>(`api/rappel/`, rappel);
  }

  findRappels(request: RappelRequest) {
    return this.httpApiService.post<Rappel[]>(`api/rappel/find`, request);
  }

  deleteRappel(id: string) {
    return this.httpApiService.delete(`api/rappel/${id}`);
  }

  forgotPassword(email: string) {
    return this.httpApiService.get(`api/back_office/user/forgotPasswordByMail/${email}/DRMILOU`);
  }
}
