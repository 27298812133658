import { UserRole } from './user.model';

export interface LogRequest {
  channel: string;
  userName: string;
  role: UserRole;
  dateLog: Date;
  payload: any;
  action: string;
  context: string;
  typeLog: TypeLog;
}

export enum TypeLog {
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export enum Context {
  DASHBOARD = 'DASHBOARD',
  PREVIEW_RDV = 'PREVIEW_RDV',
  RDV = 'RDV',
  RDV_CLIENT = 'RDV_CLIENT',
  RDV_ANIMAL = 'RDV_ANIMAL',
  RDV_CR = 'RDV_CR',
  RDV_ORDONNANCE = 'RDV_ORDONNANCE',
  HORS_RDV = 'HORS_RDV',
  RDV_FACTURE = 'RDV_FACTURE',
  EXPORT_RDV = 'EXPORT_RDV',
  STAT_RDV = 'STAT_RDV',
  HANDLE_ERROR = 'HANDLE_ERROR',
  DISCUSSIONS = 'DISCUSSIONS',
  DISCUSSION = 'DISCUSSION',
  ADMIN_VETO = 'ADMIN_VETO',
  ADMIN_ASV = 'ADMIN_ASV',
  ADMIN_STRUCTURE = 'ADMIN_STRUCTURE',
  ADMIN_TARIF = 'ADMIN_TARIF',
  ADMIN_TARIF_PRODUITS = 'ADMIN_TARIF_PRODUITS',
  ADMIN_TARIF_ACTES = 'ADMIN_TARIF_ACTES',
  ADMIN_USER_DOUBLONS = 'ADMIN_USER_DOUBLONS',
  ADMIN_ANIMAL_DOUBLONS = 'ADMIN_ANIMAL_DOUBLONS',
  PDF_FACTURE = 'PDF_FACTURE',
  PDF_CR = 'PDF_CR',
  PLANNING = 'PLANNING',
  PLANNING_VETO = 'PLANNING_VETO',
  STOCK_VETO = 'STOCK_VETO',
  STOCK_STRUCTURE = 'STOCK_STRUCTURE',
  STOCK_MOUVEMENT_ADMIN = 'STOCK_MOUVEMENT_ADMIN',
  STOCK_MOUVEMENT_VETO = 'STOCK_MOUVEMENT_VETO',
  ERREUR_COMMANDE = 'ERREUR_COMMANDE',
  COMMANDE = 'COMMANDE',
  DETAIL_COMMANDE = 'DETAIL_COMMANDE',
  MIM = 'MIM',
  DEVIS_LIST = 'DEVIS_LIST',
}

export enum Action {
  CONSULTATION = 'CONSULTATION',
  CONSULTATION_LIST = 'CONSULTATION_LIST',
  ASK_ADD_ANIMAL = 'ASK_ADD_ANIMAL',
  ADD_ANIMAL = 'ADD_ANIMAL',
  OPEN_VACATION = 'OPEN_VACATION',
  SAVE_VACATION = 'SAVE_VACATION',
  VERIFICATION_CLIENT = 'VERIFICATION_CLIENT',
  ASK_MODIFICATION_CLIENT = 'ASK_MODIFICATION_CLIENT',
  ASK_MODIFICATION = 'ASK_MODIFICATION',
  MODIFICATION_CLIENT = 'MODIFICATION_CLIENT',
  VALIDATION_PAIEMENT = 'VALIDATION_PAIEMENT',
  DELETE_PAIEMENT = 'DELETE_PAIEMENT',
  MARK_AS_PAIED = 'MARK_AS_PAIED',
  MODIFICATION = 'MODIFICATION',
  ENVOI = 'ENVOI',
  CLOTURE = 'CLOTURE',
  MODIFICATION_AUTOMATIQUE = 'MODIFICATION_AUTOMATIQUE',
  ASK_CREATION = 'ASK_CREATION',
  CREATION = 'CREATION',
  ENREGISTREMENT = 'ENREGISTREMENT',
  ACTIVER = 'ACTIVER',
  DESACTIVER = 'DESACTIVER',
  ENVOYE = 'ENVOYE',
  SUPPRIMER = 'SUPPRIMER',
  TELECHARGE = 'TELECHARGE',
  ADD_ACTE = 'ADD_ACTE',
  DELETE_ACTE = 'DElETE_ACTE',
  ADD_PRODUIT = 'ADD_PRODUIT',
  DELETE_PRODUIT = 'DElETE_PRODUIT',
  ADD_FACTURE = 'ADD_FACTURE',
  DELETE_FACTURE = 'DELETE_FACTURE',
  ADD_ORDONNANCE = 'ADD_ORDONNANCE',
  DELETE_ORDONNANCE = 'DELETE_ORDONNANCE',
  ATTRIBUTION = 'ATTRIBUTION',
  DISPONIBLE = 'DISPONIBLE',
  INDISPONIBLE = 'INDISPONIBLE',
  CHANGE_STRUCTURE = 'CHANGE_STRUCTURE',
  CHANGE_RDV_STRUCTURE = 'CHANGE_RDV_STRUCTURE',
  UNKNOWN = 'UNKNOWN',
  INIT_PLANNING = 'INIT_PLANNING',
  STOCK_MOUVEMENT_PRODUIT = 'STOCK_MOUVEMENT_PRODUIT',
  STOCK_RECEPTION_PRODUIT = 'STOCK_RECEPTION_PRODUIT',
  STOCK_REAPPRO_PRODUIT = 'STOCK_REAPPRO_PRODUIT',
  VALIDATION_COMMANDE_UNIQUE = 'VALIDATION_COMMANDE_UNIQUE',
  VALIDATION_COMMANDE_MULTIPLE = 'VALIDATION_COMMANDE_MULTIPLE',
  MODIFICATION_STATUT_COMMANDE = 'MODIFICATION_STATUT_COMMANDE',
  ANNULATION_RDV = 'ANNULATION_RDV',
  TRY_MOVE_RDV = 'TRY_MOVE_RDV',
  SWAP_RDV = 'SWAP_RDV',
  MOVE_RDV_VALID = 'MOVE_RDV_VALID',
  OPEN_PREVIEW_RDV = 'OPEN_PREVIEW_RDV',
  OPEN_PREVIEW_RDV_INTERNE = 'OPEN_PREVIEW_RDV_INTERNE',
  UPDATE_RDV = 'UPDATE_RDV',
  CREATE_APPEL = 'CREATE_APPEL',
  SAVE_APPEL = 'SAVE_APPEL',
  CREATE_RDV = 'CREATE_RDV',
  SAVE_RDV = 'SAVE_RDV',
  SAVE_RDV_INTERNE = 'SAVE_RDV_INTERNE',
  OPEN_MAP = 'OPEN_MAP',
  APPLIQUER_DEVIS = 'APPLIQUER_DEVIS',
}
