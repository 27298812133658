import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RdvDomicileService } from '@core/services/consultations/rdv-domicile.service';
import { ModalController } from '@ionic/angular';
import { Facturation, HorsRdv, HorsRdvStep, Prestation } from '../../../../models/rdv.model';
import { Adresse, User, UserRole, VilleForm } from '../../../../models/user.model';
import { Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { Veterinaire } from '../../../../models/admin.model';
import { HorsRdvService } from '@core/services/consultations/hors-rdv.service';
import { Interaction, TypeCanal, TypeHorsRdv, TypeInteraction, TypeRdv } from '../../../../models/interaction.model';
import { Paiement } from '../../../../models/paiement.model';
import { InteractionComponent } from '../../../shared/components/interactions/interaction.component';
import { InteractionService } from '@core/services/interaction.service';
import { Ordonnance } from '../../../../models/ordonnance.model';
import { FacturationComponent } from '../../../shared/components/facturation/facturation.component';
import { PrescriptionStepComponent } from '../../../rdv/wizard/components/prescription/prescription-step.component';
import { OrdonnanceService } from '../../../rdv/wizard/services/ordonnance.service';
import { FacturationService } from '../../../rdv/wizard/services/facturation.service';

@Component({
  selector: 'preview-hors-rdv',
  templateUrl: 'preview-hors-rdv.page.html',
  styleUrls: ['preview-hors-rdv.page.scss', '../../../planning/pages/planning/planning.page.scss'],
})
export class PreviewHorsRdvPage implements OnInit, OnDestroy {
  @Input() horsRdv!: HorsRdv;
  @Input() vetos?: Veterinaire[];
  @Input() user!: User;

  newRdvStep!: HorsRdvStep;
  ordonnances: Ordonnance[] = [];
  facturations: Facturation[] = [];
  @ViewChild('facturationComponent') facturationComponent?: FacturationComponent;
  @ViewChild('ordoComponent') ordoComponent?: PrescriptionStepComponent;

  cityList!: VilleForm[] | undefined;

  get factuForm(): FormGroup | undefined {
    return this.facturationComponent?.form;
  }

  get ordoForm(): FormGroup | undefined {
    return this.ordoComponent?.form;
  }

  private readonly destroy$: Subject<void>;
  protected readonly UserRole = UserRole;
  isEditable: boolean | null = false;
  horsRdvForm: FormGroup;
  montant?: number;
  private isSavingRdv = false;
  rdvStepSelectionnables: HorsRdvStep[] = [];
  hasUpdatedPaiements = false;
  protected readonly TypeHorsRdv = TypeHorsRdv;
  protected readonly TypeRdv = TypeRdv;
  paiements: Paiement[] = [];
  private hasModifiedInteractions = false;

  constructor(
    private readonly horsRdvService: HorsRdvService,
    private readonly fb: FormBuilder,
    private readonly cd: ChangeDetectorRef,
    private readonly rdvService: RdvDomicileService,
    private readonly interactionService: InteractionService,
    private readonly modalCtrl: ModalController,
  ) {
    this.destroy$ = new Subject<void>();

    this.horsRdvForm = this.fb.group({
      description: [null],
      adresse: [null, [Validators.required]],
      codePostal: [null, [Validators.required]],
      ville: [null, [Validators.required]],
      detailAdresse: [null],
      codeInsee: [null],
      responsable: [null, [Validators.required]],
    });
  }

  ngOnInit() {
    this.rdvStepSelectionnables = HorsRdvService.getStepsSelectionnables(this.horsRdv.step, this.user);
    this.montant =
      this.horsRdv.prestations && this.horsRdv.paiements
        ? this.horsRdv.prestations
            .flatMap(p => p.facturations || [])
            .map(f => f.montant!)
            .reduce((m1, m2) => m1 + m2, 0) - this.horsRdv.paiements.map(p => p.montant).reduce((p1, p2) => p1 + p2, 0)
        : 0;

    if (this.horsRdv.responsable) {
      this.horsRdvForm.get('responsable')!.setValue(this.vetos?.find(v => v.utilisateur?.id === this.horsRdv.responsable!.id));
    }
    this.isEditable = this.isHorsRdvEditable(this.horsRdv, this.user);
    this.paiements = this.horsRdv.paiements ? [...this.horsRdv.paiements] : [];
    this.newRdvStep = this.horsRdv.step;
    if (!this.rdvStepSelectionnables.includes(this.horsRdv.step)) {
      this.rdvStepSelectionnables.unshift(this.horsRdv.step);
    }

    this.horsRdvForm.patchValue({
      description: this.horsRdv.description,
      adresse: this.horsRdv.adresse?.adresse,
      codePostal: this.horsRdv.adresse?.codePostal,
      ville: this.horsRdv.adresse?.ville,
      detailAdresse: this.horsRdv.adresse?.detail,
    });

    if (this.horsRdv.adresse?.codePostal) {
      this.getVilleRdv(this.horsRdv.adresse?.codePostal);
    }
    this.cd.markForCheck();
  }

  private isHorsRdvEditable(horsRdv: HorsRdv, user: User) {
    if (!user) {
      return false;
    }

    if (user.role === UserRole.ROLE_ADMIN) {
      return true;
    }

    if (user.role === UserRole.ROLE_BACKOFFICE || (user.role === UserRole.ROLE_VETERINAIRE && horsRdv.responsable?.id === user.id)) {
      return ![HorsRdvStep.ANNULE, HorsRdvStep.CLOTURE].includes(horsRdv.step);
    }

    return false;
  }

  getVilleRdv(codePostal: string) {
    this.rdvService.getPostalCode(codePostal).subscribe(villes => {
      if (villes) {
        this.cityList = villes;
        if (villes.length === 1) {
          this.horsRdvForm.get('ville')!.setValue(villes[0]);
        } else {
          this.horsRdvForm
            .get('ville')!
            .setValue(
              villes.find(
                v =>
                  (v.code === this.horsRdv.adresse?.codeInsee && v.nom.toLowerCase() === this.horsRdv?.adresse?.ville?.toLowerCase()) ||
                  v.nom.toLowerCase() === this.horsRdv?.adresse?.ville?.toLowerCase(),
              ),
            );
        }
      }
    });
  }

  cancel() {
    if (this.hasUpdatedPaiements || this.hasModifiedInteractions) {
      this.modalCtrl.dismiss({ ...this.horsRdv, paiements: this.paiements });
    } else {
      this.modalCtrl.dismiss();
    }
  }

  getCityCode(event: any) {
    const codePostal = event.target.value;
    this.horsRdvForm.patchValue({ ville: '' });
    if (codePostal.length !== 5) {
      this.horsRdvForm.get('ville')?.disable();
    }
    if (codePostal.length === 5 && this.horsRdvForm.value.codePostal) {
      this.horsRdvForm.get('ville')?.enable();
      this.getVilleRdv(codePostal);
    }
  }

  saveRdv() {
    this.montant = this.facturations.map(f => f.montant!).reduce((m1, m2) => m1 + m2, 0) - this.paiements.map(p => p.montant).reduce((p1, p2) => p1 + p2, 0);

    let rdvToSave = this.buildNewRdv();
    this.horsRdvService
      .updateHorsRdv(rdvToSave)
      .pipe(
        filter(() => !this.isSavingRdv),
        tap(() => (this.isSavingRdv = true)),
      )
      .subscribe({
        next: (rdv: HorsRdv) => {
          this.modalCtrl.dismiss(rdv);

          this.isSavingRdv = false;
        },
        complete: () => {
          this.isSavingRdv = false;
        },
        error: err => {
          console.error(err);
          this.isSavingRdv = false;
        },
      });
  }

  private buildNewRdv(): HorsRdv {
    const newRdv: HorsRdv = { ...this.horsRdv, prestations: this.generatePrestasHorsRdv() };
    let adresseRdv: Adresse | undefined;
    adresseRdv = {
      adresse: this.horsRdvForm.value.adresse,
      codePostal: this.horsRdvForm.value.codePostal,
      codeInsee: this.horsRdvForm.value.ville.code ? this.horsRdvForm.value.ville.code : this.horsRdvForm.value.codeInsee,
      ville: this.horsRdvForm.value.ville.nom ? this.horsRdvForm.value.ville.nom : this.horsRdvForm.value.ville,
      detail: this.horsRdvForm.value.detailAdresse,
    };

    return {
      ...newRdv,
      description: this.horsRdvForm.value.description,
      step: this.newRdvStep,
      adresse: adresseRdv,
      paiements: this.paiements,
    };
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateRdvState(ev: any) {
    this.newRdvStep = ev.target.value;
  }

  addCrAppel(appel: any) {
    this.addInteraction(TypeInteraction.CALL_CLIENT, appel);
  }

  addInteraction(type: TypeInteraction, contenu: string) {
    if (contenu) {
      const interactionToSend: Interaction = {
        initiateur: this.user as { id: string; nom: string; prenom: string; role: UserRole },
        destinataire: { id: this.horsRdv.client.id },
        dateEnvoi: new Date(),
        type: type,
        typeRdv: TypeRdv.DOMICILE,
        contenu: contenu,
        canal: TypeCanal.WEB,
        priorite: 1,
      };
      this.interactionService.addInteractionRdv(this.horsRdv.id, interactionToSend).subscribe(interaction => {
        if (this.horsRdv.interactions) {
          this.horsRdv.interactions.unshift(interaction);
        } else {
          this.horsRdv.interactions = [interaction];
        }
        this.hasModifiedInteractions = true;
      });
    }
  }

  async openInteraction() {
    const modal = await this.modalCtrl.create({
      component: InteractionComponent,
      componentProps: {
        interactions: this.horsRdv.interactions,
      },
      cssClass: 'preview-rdv-modal-size',
    });

    await modal.present();
  }

  private generatePrestasHorsRdv(): Prestation[] {
    if (this.horsRdv.type === TypeHorsRdv.ORDONNANCE) {
      this.ordonnances = OrdonnanceService.buildOrdonnances(this.ordoForm?.controls['ordonnances'] as FormArray);

      return [
        {
          ordonnances: this.ordonnances,
          titre: `Ordonnance pour ${this.horsRdv.animaux![0]!.nom}`,
          clientId: this.horsRdv.client!.id,
          typeRdv: TypeRdv.HORS_RDV,
          consultant: this.horsRdvForm.value.responsable.utilisateur,
          animal: this.horsRdv.animaux![0],
          datePrestation: new Date(),
        },
      ];
    } else {
      this.facturations = FacturationService.buildFacturations(this.factuForm?.controls['facturations'] as FormArray);

      return this.facturations.map((f, idx) => ({
        facturations: [f],
        titre: `Envoi de médicament pour ${this.horsRdv.animaux![idx].nom}`,
        clientId: this.horsRdv.client!.id,
        typeRdv: TypeRdv.HORS_RDV,
        consultant: this.horsRdvForm.value.responsable.utilisateur,
        animal: this.horsRdv.animaux![idx],
        datePrestation: new Date(),
      }));
    }
  }

  protected readonly TypeCanal = TypeCanal;
  protected readonly TypeInteraction = TypeInteraction;
}
