import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ModalController } from '@ionic/angular';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { distinctUntilChanged, Observable, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { selectAllEspece, selectAllRaces, selectAnimalClientList } from '@core/store/selector/rendezvous.selector';
import { Animal, Espece, Race } from 'app/models/animal.model';
import { Store } from '@ngrx/store';
import { RdvDomicileAction } from '@core/store/actions/rdv-domicile.action';
import { Action, Context, TypeLog } from '../../../../models/log.model';
import { LogService } from '@core/services/log/log.service';
import { AnimalService } from '../../../rdv/wizard/services/animal.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'change-animal-component',
  templateUrl: './change-animal.component.html',
  styleUrls: ['./change-animal.component.scss'],
})
export class ChangeAnimalComponent implements OnInit, OnDestroy {
  createOnly!: boolean;
  animauxRdv?: Animal[];
  animalClientList$: Observable<Animal[] | undefined>;
  changeAnimalForm: FormGroup = this.formBuilder.group({
    animal: [null, [Validators.required]],
  });
  form: FormGroup;
  showList = true;
  especeList: Observable<Espece[] | undefined>;
  race$: Observable<Race[] | undefined>;
  selectedRace!: Race | undefined;
  idClient!: string;

  private readonly destroy$: Subject<void> = new Subject<void>();

  hasDoublon = false;
  currentSegment = 'animal-list';
  nbAnxSelectionnables?: number;

  constructor(
    private readonly store: Store,
    private readonly formBuilder: FormBuilder,
    private readonly modalCtrl: ModalController,
    private readonly animalService: AnimalService,
    private readonly logService: LogService,
    private readonly datePipe: DatePipe,
  ) {
    this.form = this.formBuilder.group({
      id: [null],
      animalNom: [null, [Validators.required]],
      animalSexe: [null, [Validators.required]],
      animalEspece: [null, [Validators.required]],
      animalRace: [null, [Validators.required]],
      animalCroise: [null],
      animalNaissance: [null, [Validators.required]],
      animalSterilise: [null, [Validators.required]],
      animalTatouage: [null],
      animalPuce: [null],
      animalPoids: [null],
      animalRemarque: [null],
      idVetophonie: [null],
      animalCouleur: [null],
      animalCommentaireInterne: [null],
    });

    this.animalClientList$ = this.store.select(selectAnimalClientList).pipe(distinctUntilChanged(), takeUntil(this.destroy$));
    this.especeList = this.store.select(selectAllEspece).pipe(distinctUntilChanged(), takeUntil(this.destroy$));
    this.race$ = this.store.select(selectAllRaces).pipe(distinctUntilChanged(), takeUntil(this.destroy$));
  }

  ngOnInit(): void {
    if (this.createOnly) {
      this.showList = false;
    } else {
      this.animalClientList$.pipe(map(anx => anx?.filter(a => !this.isAnimalNonSelectionnable(a)))).subscribe(anx => {
        this.nbAnxSelectionnables = anx?.length || 0;
        if (!anx || anx.length <= 0) {
          this.currentSegment = 'new-animal';
          this.showList = false;
        }
      });
    }
  }

  confirm() {
    if (this.form.valid && !this.showList) {
      const animalData = this.buildAnimal();
      this.logService.log(Context.RDV, Action.ADD_ANIMAL, TypeLog.INFO, animalData);
      if (animalData.dateNaissance && !this.hasDoublon) {
        this.animalService
          .countDoublonsAnimal(animalData.nom, animalData.dateNaissance)
          .pipe(
            switchMap(count => {
              if (count > 0) {
                this.hasDoublon = true;

                return of({});
              } else {
                return this.animalService.addAnimal(animalData).pipe(tap(newAnimal => this.modalCtrl.dismiss(newAnimal)));
              }
            }),
          )
          .subscribe();
      } else {
        this.animalService
          .addAnimal(animalData)
          .pipe(tap(newAnimal => this.modalCtrl.dismiss(newAnimal)))
          .subscribe();
      }
    } else {
      this.modalCtrl.dismiss(this.changeAnimalForm.value.animal);
    }
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  changeSegment() {
    this.changeAnimalForm.reset();
    this.form.reset();
    this.showList = !this.showList;
  }

  getRaces(event: any) {
    const especeSelected = event.target.value.nom;

    if (especeSelected) {
      this.selectedRace = undefined;
    }
    if (especeSelected !== undefined) {
      this.store.dispatch(RdvDomicileAction.getanimalrace({ espece: especeSelected }));
    }
  }

  selectRaceChanged(ev: any) {
    this.selectedRace = ev;

    // this.form.patchValue({ animalRace: this.selectedRace });
  }

  private buildAnimal(): Animal {
    const formValue = this.form.getRawValue();

    return {
      id: formValue.id,
      idVetophonie: formValue.idVetophonie,
      dateNaissance: this.datePipe.transform(formValue.animalNaissance, 'yyyy-MM-dd')!,
      nom: formValue.animalNom,
      espece: formValue.animalEspece,
      race: formValue.animalRace,
      poids: formValue.animalPoids,
      puce: formValue.animalPuce,
      tatouage: formValue.animalTatouage,
      sexe: formValue.animalSexe,
      sterilise: formValue.animalSterilise,
      commentaire: formValue.animalRemarque,
      commentaireInterne: formValue.animalCommentaireInterne,
      couleur: formValue.animalCouleur,
      croise: formValue.animalCroise,
      user: {
        id: this.idClient,
      },
    };
  }

  isAnimalNonSelectionnable(animal: Animal) {
    return this.animauxRdv?.map(a => a.id)?.includes(animal.id) || animal.decede || animal.motifSeparation !== null;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
