import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Animal } from 'app/models/animal.model';
import { Prestation, RdvState } from 'app/models/rdv.model';
import { Store } from '@ngrx/store';
import { filter, Subject, Subscription, takeUntil } from 'rxjs';
import { ProduitActions } from '@core/store/actions/produit.actions';
import { Actions, ofType } from '@ngrx/effects';
import { AlertController, ModalController } from '@ionic/angular';
import { sortBy } from 'lodash';
import { LogService } from '@core/services/log/log.service';
import { User, UserRole } from 'app/models/user.model';
import { Browser } from 'leaflet';
import { Produit } from '../../../../models/produit.model';
import { ReferenceService } from '../../../rdv/wizard/services/reference.service';
import { EtatOrdonnanceEnum, Ordonnance } from '../../../../models/ordonnance.model';
import { EnvoiOrdonnanceComponent } from '../../../rdv/wizard/components/prescription/envoi-ordonnance/envoi-ordonnance.component';
import { Action, Context, TypeLog } from '../../../../models/log.model';
import { OrdonnanceService } from '../../../rdv/wizard/services/ordonnance.service';
import { FacturationService } from '../../../rdv/wizard/services/facturation.service';
import mobile = Browser.mobile;

@Component({
  selector: 'ordonnanceComponent',
  templateUrl: './ordonnance.component.html',
  styleUrls: ['./ordonnance.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrdonnanceComponent implements OnChanges, OnDestroy {
  form!: FormGroup;
  @Input() mailClient!: string;
  @Input() ordoEditable!: boolean;
  @Input() animal!: Animal | null;
  @Input() user!: User;
  @Input() prestation?: Prestation;
  @Input() generateFirstOrdo = false;
  @Input() displaySaveBtns = true;
  @Input() displayDelivre = true;

  produitSub?: Subscription;
  produits: Produit[] = [];
  currentOrdoIdxForSearch?: number;
  currentProduitIdxForSearch?: number;
  private destroy$: Subject<void>;

  listIdOrdonnancesPayees: string[] = [];

  savingOrdoIdx?: number;

  mobile;
  protected readonly RdvState = RdvState;
  private oldPresta?: Prestation;

  constructor(
    private readonly fb: FormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly store: Store,
    private readonly referenceService: ReferenceService,
    private readonly actions$: Actions,
    private readonly alertController: AlertController,
    private readonly logService: LogService,
    private readonly modalController: ModalController,
  ) {
    this.mobile = mobile;
    this.form = this.fb.group({
      ordonnances: this.fb.array([]),
    });
    this.destroy$ = new Subject<void>();

    this.actions$.pipe(ofType(ProduitActions.saveordonnancesuccess), takeUntil(this.destroy$)).subscribe(action => {
      if (this.savingOrdoIdx !== undefined) {
        const ordoCtrl = this.ordonnances.at(this.savingOrdoIdx);
        ordoCtrl.get('id')!.setValue(action.ordonnance.id, { emitEvent: false });
        ordoCtrl.get('numOrdonnance')!.setValue(action.ordonnance.numOrdonnance, { emitEvent: false });
        ordoCtrl.get('etatOrdonnance')!.setValue(EtatOrdonnanceEnum.TERMINEE, { emitEvent: false });
        ordoCtrl.markAsPristine();
        this.savingOrdoIdx = undefined;
        this.changeDetectorRef.markForCheck();
      }
    });

    this.actions$.pipe(ofType(ProduitActions.deleteordonnancesuccess), takeUntil(this.destroy$)).subscribe(action => {
      this.ordonnances.removeAt(this.ordonnances.controls.map(control => control.get('id')!.value).indexOf(action.idOrdonnance));
      this.currentOrdoIdxForSearch = -1;
      this.produits = [];
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnChanges() {
    if (this.oldPresta) {
      // pour ne charger qu'une fois
      return;
    }

    this.loadOrdonnancesForm();

    if (!this.ordonnances.controls.length && this.generateFirstOrdo) {
      this.addOrdonnance();
    }
    this.oldPresta = this.prestation;
  }

  private loadOrdonnancesForm() {
    if (!(this.ordoEditable && this.prestation && this.prestation.ordonnances && this.prestation.ordonnances.length > 0)) {
      return;
    }
    this.ordonnances.clear();
    sortBy(this.prestation.ordonnances, ['numOrdonnance']).forEach(ordonnance => {
      const prescription = this.fb.array(
        [...ordonnance.produitPrescrits]
          ?.sort((p1, p2) => (p1.index !== undefined && p2.index !== undefined ? (p1.index > p2.index ? 1 : -1) : 1))
          .map(produit =>
            this.fb.group({
              id: [produit.id],
              produit: [produit.produit],
              produitSearch: [produit.produit?.nom ? FacturationService.getProduitName(produit.produit) : produit?.nom, [Validators.required]],
              posologie: [produit.posologie, [Validators.required]],
              numLot: [produit.numLot ? produit.numLot : ''],
              renouvelableFois: [produit.renouvelableFois ? produit.renouvelableFois : 0, [Validators.required]],
              quantity: [produit.quantiteUvp, [Validators.required]],
              isDelivered: [produit.delivre, [Validators.required]],
            }),
          ),
      );

      const ordonnanceCtrl = this.fb.group({
        id: [ordonnance.id],
        etatOrdonnance: [ordonnance.etat],
        numOrdonnance: [ordonnance.numOrdonnance],
        infosSupplementaires: [ordonnance.infosSupplementaires],
        prescription,
      });
      if (ordonnance.etat === EtatOrdonnanceEnum.ENVOYEE) {
        ordonnanceCtrl.disable();
      } else {
        ordonnanceCtrl.valueChanges
          .pipe(
            takeUntil(this.destroy$),
            filter(() => ordonnanceCtrl.get('etatOrdonnance')!.value === EtatOrdonnanceEnum.TERMINEE),
          )
          .subscribe(() => {
            ordonnanceCtrl.get('etatOrdonnance')!.setValue(EtatOrdonnanceEnum.EN_COURS, { emitEvent: false });
          });
      }
      this.ordonnances.push(ordonnanceCtrl, { emitEvent: false });
    });
    this.changeDetectorRef.markForCheck();
  }

  get ordonnances() {
    return this.form.controls['ordonnances'] as FormArray;
  }

  addOrdonnance() {
    if (this.prestation) {
      this.logService.log(Context.RDV_ORDONNANCE, Action.ADD_ORDONNANCE, TypeLog.INFO, this.prestation.id);
    } else {
      this.logService.log(Context.HORS_RDV, Action.ADD_ORDONNANCE, TypeLog.INFO, this.mailClient);
    }
    const newLine = this.fb.group({
      id: [''],
      etatOrdonnance: [EtatOrdonnanceEnum.EN_COURS],
      numOrdonnance: [''],
      infosSupplementaires: [''],
      prescription: this.fb.array([]),
    });
    this.ordonnances.push(newLine);
    const idxOrdo = this.ordonnances.length - 1;
    newLine.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter(() => newLine.get('etatOrdonnance')!.value === EtatOrdonnanceEnum.TERMINEE),
      )
      .subscribe(() => {
        newLine.get('etatOrdonnance')!.setValue(EtatOrdonnanceEnum.EN_COURS);
      });
    this.addPrescription(idxOrdo);
  }

  deleteOrdonnance(index: number) {
    this.alertController
      .create({
        header: 'Êtes-vous sûr de vouloir supprimer cette ordonnance ?',
        buttons: [
          {
            text: 'Non',
            handler: () => {},
          },
          {
            text: 'Oui',
            handler: () => {
              if (this.prestation) {
                this.logService.log(Context.RDV_ORDONNANCE, Action.DELETE_ORDONNANCE, TypeLog.INFO, {
                  idPrestation: this.prestation.id,
                  index: index,
                });
              } else {
                this.logService.log(Context.HORS_RDV, Action.DELETE_ORDONNANCE, TypeLog.INFO, {
                  mailClient: this.mailClient,
                  index: index,
                });
              }

              const id = this.ordonnances.at(index).get('id')!.value;
              if (id) {
                this.store.dispatch(ProduitActions.deleteordonnance({ idOrdonnance: id }));
              } else {
                this.ordonnances.removeAt(index);
                this.currentOrdoIdxForSearch = -1;
                this.produits = [];
              }

              this.form.markAsDirty();
              this.changeDetectorRef.markForCheck();
            },
          },
        ],
      })
      .then(res => {
        res.present();
      });
  }

  prescription(idx: number) {
    return this.ordonnances.at(idx).get('prescription') as FormArray;
  }

  addPrescription(idx: number) {
    this.logService.log(Context.RDV_ORDONNANCE, Action.ADD_PRODUIT, TypeLog.INFO, { idxOrdonnance: idx });

    const newLine = this.fb.group({
      id: [null],
      produit: [null],
      produitSearch: ['', [Validators.required]],
      posologie: ['', [Validators.required]],
      quantity: [1, [Validators.required]],
      numLot: [''],
      renouvelableFois: [0, [Validators.required]],
      isDelivered: [this.displayDelivre, [Validators.required]],
    });

    this.prescription(idx).push(newLine);
    this.prescription(idx).markAsDirty();
  }

  supprimerProduit(idxOrdonnance: number, index: number) {
    this.logService.log(Context.RDV_ORDONNANCE, Action.DELETE_PRODUIT, TypeLog.INFO, {
      idxOrdonnance: idxOrdonnance,
      index: index,
    });

    this.prescription(idxOrdonnance).removeAt(index);
    this.prescription(idxOrdonnance).markAsDirty();
    if (0 === this.prescription(idxOrdonnance).length) {
      this.addPrescription(idxOrdonnance);
    }
  }

  searchProduit(event: any, idxOrdonnance: number, idx: number) {
    this.currentOrdoIdxForSearch = idxOrdonnance;
    this.currentProduitIdxForSearch = idx;
    this.prescription(idxOrdonnance).at(idx).get('produit')!.setValue(null);
    if (event.target.value?.length > 2) {
      if (this.produitSub) {
        this.produitSub.unsubscribe();
      }
      this.produitSub = this.referenceService
        .searchProduits({ request: event.target.value, visible: true })
        .pipe(takeUntil(this.destroy$))
        .subscribe(produits => {
          this.produits = produits.elements;
          this.changeDetectorRef.markForCheck();
        });
    } else {
      this.produits = [];
      this.changeDetectorRef.markForCheck();
    }
  }

  selectProduit(produit: Produit, idxOrdonnance: number, idx: number) {
    this.prescription(idxOrdonnance).at(idx).get('produit')!.setValue(produit);
    this.prescription(idxOrdonnance).at(idx).get('produitSearch')!.setValue(FacturationService.getProduitName(produit));
  }

  saveOrdonnance(idxOrdo: number) {
    const ordoCtrl = this.ordonnances.at(idxOrdo);
    const ordonnance: Ordonnance = OrdonnanceService.buildOrdonnance(ordoCtrl as FormGroup);
    ordonnance.etat = EtatOrdonnanceEnum.TERMINEE;
    this.savingOrdoIdx = idxOrdo;

    this.logService.log(Context.RDV_ORDONNANCE, Action.MODIFICATION, TypeLog.INFO, ordonnance);
    this.store.dispatch(ProduitActions.saveordonnance({ ordonnance, idPrestation: this.prestation!.id! }));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async envoyerOrdonnanceClotureeParMail(ordo: Ordonnance) {
    const modal = await this.modalController.create({
      component: EnvoiOrdonnanceComponent,
      cssClass: 'envoi-ordonnance-modal',
      componentProps: {
        title: ordo.etat === EtatOrdonnanceEnum.ENVOYEE ? `Renvoyer l'ordonnance` : `Envoyer l'ordonnance`,
        email: this.mailClient,
        idOrdonnance: ordo.id,
        idPrestation: this.prestation?.id,
      },
    });

    await modal.present();
  }

  async envoyerOrdonnanceParMail(idxOrdo: number) {
    const modal = await this.modalController.create({
      component: EnvoiOrdonnanceComponent,
      cssClass: 'envoi-ordonnance-modal',
      componentProps: {
        title: this.ordonnances.at(idxOrdo).get('etatOrdonnance')!.value === EtatOrdonnanceEnum.ENVOYEE ? `Renvoyer l'ordonnance` : `Envoyer l'ordonnance`,
        email: this.mailClient,
        idPrestation: this.prestation?.id,
        idOrdonnance: this.ordonnances.at(idxOrdo).get('id')!.value,
      },
    });

    modal.onDidDismiss().then(data => {
      if (data.data) {
        this.ordonnances.at(idxOrdo).get('etatOrdonnance')!.setValue(EtatOrdonnanceEnum.ENVOYEE, { emitEvent: false });
        this.ordonnances.at(idxOrdo).disable();

        this.changeDetectorRef.markForCheck();
      }
    });
    await modal.present();
  }

  protected readonly UserRole = UserRole;
}
