import { Animal } from './animal.model';
import { Adresse, Groupe, User, UserRole } from './user.model';
import { CompteRendu } from './compte-rendu.model';
import { Ordonnance } from './ordonnance.model';
import { ProduitDelivre } from './produit.model';
import { Paiement } from './paiement.model';
import { Consentement } from './consentement.model';
import { Interaction, TypeHorsRdv, TypeRdv } from './interaction.model';

export enum RdvInterneType {
  PHYSIQUE = 'PHYSIQUE',
  VIRTUEL = 'VIRTUEL',
}

export interface MotifAnnulation {
  code: string;
  libelle: string;
}

export enum RdvState {
  INIT = 'INIT',
  ABANDONNEE = 'ABANDONNEE',
  FROM_VETOPHONIE = 'FROM_VETOPHONIE',
  CONFIRM = 'CONFIRM',
  PAIEMENT_EN_COURS = 'PAIEMENT_EN_COURS',
  PAIEMENT_OK = 'PAIEMENT_OK',
  TERMINEE = 'TERMINEE',
  ANNULEE = 'ANNULEE',
  REPLANIFICATION = 'REPLANIFICATION',
  // full milou
  RDV_A_CONFIRMER = 'RDV_A_CONFIRMER',
  RDV_CONFIRME = 'RDV_CONFIRME',
  RDV_EN_ROUTE = 'RDV_EN_ROUTE',
  RDV_EN_COURS = 'RDV_EN_COURS',
  RDV_TERMINE = 'RDV_TERMINE',
  RDV_CLOTURE = 'RDV_CLOTURE',
  RDV_ANNULE = 'RDV_ANNULE',
  RDV_EN_ATTENTE = 'RDV_EN_ATTENTE',
  RDV_VERIFIE = 'RDV_VERIFIE',
}

export enum HorsRdvStep {
  INIT = 'INIT',
  PAIEMENT_EN_COURS = 'PAIEMENT_EN_COURS',
  PAIEMENT_OK = 'PAIEMENT_OK',
  CLOTURE = 'CLOTURE',
  ANNULE = 'ANNULE',
}

export interface Acte {
  dureeRdv?: number;
  dureeRdvSupplementaireParAnimal?: number;
  code: string;
  commentaire: string | null;
  id: string;
  idVetophonie: string | null;
  indexOrder: number | null;
  libelle: string;
  tarifMinHT: number;
  tarifMaxHT: number;
  tarif: string;
  idStructure: string;
  categorie: string;
  visibleClient: boolean;
  visibleVeto: boolean;
  consultation: boolean;
}

export interface ActeRealise {
  id?: string;
  description?: string;
  tarifHT: number;
  remise?: number;
  quantite?: number;
  nom?: string;
  acte?: Acte;
  index?: number;
}

export interface StructureVeterinaire {
  actes?: Acte[];
  id?: string;
  idSiteEvoliz: number;
  idStripe?: string | number | null;
  idVetophonie: number;
  idWorkPlace?: string | number | null;
  nomStructure: string;
  codeStructure: string;
  typeStructure: string;
  groupe: Groupe;
  indisponible?: string;
  adresseFacturation?: AdresseStructure;
  adresseLivraisonPreferee?: AdresseStructure;
  adresses?: AdresseStructure[];
  urlSatisfaction: string;
  numeroTelephone?: string;
  plageCreneau?: number;
}

export interface AdresseStructure {
  adresse: string;
  codePostal: string;
  codeInsee: string;
  ville: string;
  detail: string;
  codeCentravet: string;
  id?: string;
  nom: string;
  codeAdresseCentravet: string;
  urlSatisfaction?: string;
  numeroTelephone?: string;
}

export interface FindRdvRequest {
  size?: number;
  offset?: number;
  page?: number;
  consultantId?: string;
  minDate?: string;
  maxDate?: string;
  minDateConsultation?: string;
  maxDateConsultation?: string;
  clientId?: string;
  animalId?: string;
  notRdvStep?: RdvState[];
  isRdvStep?: RdvState[];
  withPrestations?: boolean;
  paiementEnCours?: boolean;
  structureIds?: string[];
  order?: OrderDirectionEnum;
}

export interface FindHorsRdvRequest {
  size?: number;
  offset?: number;
  page?: number;
  responsableId?: string;
  minDate?: string;
  maxDate?: string;
  clientId?: string;
  animalId?: string;
  notRdvStep?: HorsRdvStep[];
  isRdvStep?: HorsRdvStep[];
  withPrestations?: boolean;
  structureIds?: string[];
  order?: OrderDirectionEnum;
}

export enum OrderDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface StatRdvDomicile {
  nbClient: number;
  nbRepeat: number;
  nbNouveauClient: number;
  nbConsultation: number;
  chiffreAffaireHT: number;
  chiffreAffaireTTC: number;
  nbMedicament: number;
  nbHorsRdvAutre: number;
  nbConsultationAdministratif: number;
  nbVisio: number;
  nbRegulation: number;
  nbTeleconsultation: number;
  nbPrestationVaccin: number;
  nbPrestationEuthanasie: number;
  nbPrestationAutre: number;
  nbPrestationCategorieNull: number;
  nbAnimauxChat: number;
  nbAnimauxChien: number;
  nbAnimauxAutre: number;
  nbAnimauxNull: number;
  nbDemande: number;
  nbAnnulesIndispo: number;
  nbAnnulesAutreStructure: number;
  nbAnnulesAutre: number;
  nbAnnulesIndispoVeto: number;
  nbAnnulesDeces: number;
  nbAnnulesRetablissement: number;
  nbAnnulesNoShow: number;
  nbAnnulesNonFaisableDomicile: number;
  nbAnnulesTarif: number;
  nbAnnulesComportement: number;
  nbAnnulesDoublon: number;
  nbAnnulesSansMotif: number;
  nbAbandonIndispo: number;
  nbAbandonAutreStructure: number;
  nbAbandonAutre: number;
  nbAbandonIndispoVeto: number;
  nbAbandonDeces: number;
  nbAbandonRetablissement: number;
  nbAbandonNoShow: number;
  nbAbandonNonFaisableDomicile: number;
  nbAbandonTarif: number;
  nbAbandonComportement: number;
  nbAbandonDoublon: number;
  nbAbandonSansMotif: number;
  nbAnnules: number;
  nbAbandons: number;
  recapClient: RecapClient;
  consultationsByStructure: ConsultationsByStructure[];
}

export interface ConsultationsByStructure {
  structure: string;
  nbConsultation: number;
}

export interface RecapClient {
  nbClient: number;
  nbClientAccount: number;
  nbClientActiveWithAccount: number;
  nbClientActiveWithoutAccount: number;
  nbNewClientConsultationWithConsultAndNoRegulation: number;
  nbNewClientConsultationWithConsultAndRegulation: number;
  nbNewClientConsultationWithNoConsultAndNoRegulation: number;
  nbNewClientConsultationWithNoConsultAndRegulation: number;
  nbNewClientRegulationWithConsultAndNoRegulation: number;
  nbNewClientRegulationWithConsultAndRegulation: number;
  nbNewClientRegulationWithNoConsultAndNoRegulation: number;
  nbNewClientRegulationWithNoConsultAndRegulation: number;
}

export interface Rdv {
  id: string;
  infosClientRenseignees?: boolean;
  animaux: Animal[];
  client: Client;
  commentaire?: string;
  crAppel?: string;
  consultantAssigne?: User;
  createDateTime: string;
  dateConsultation?: string;
  dateDemande: string;
  dateSouhaitee: string;
  dateCreneauRappel?: string;
  finalState: boolean;
  montant?: number;
  motif?: string;
  paiements: Paiement[];
  promotion?: string;
  rdvStep: RdvState;
  acte?: Acte;
  structureVeterinaire: StructureVeterinaire;
  updateDateTime?: string;
  url?: string;
  typeRdv?: TypeRdv;
  infosVerifieesAsv?: boolean;
  motifAnnulation?: string;
  libelleMotifAnnulation?: string;
  dateAnnulation?: string;
  previousStep?: RdvState;
  interactions?: Interaction[];
  titre?: string;
  auteurId?: string;
}

export interface RdvInterne {
  id: string;
  commentaire?: string;
  consultant?: User;
  createDateTime: string;
  dateRdv?: string;
  motif?: string;
  structureVeterinaire: StructureVeterinaire;
  updateDateTime?: string;
  typeRdvInterne?: RdvInterneType;
  adresse?: Adresse;
  adresseStructure?: AdresseStructure;
  dureeRdv?: number;
  rdvStep: RdvState;
}

export interface HorsRdv {
  id: string;
  step: HorsRdvStep;
  type: TypeHorsRdv;
  description?: string;
  client: Client;
  interactions?: Interaction[];
  montant?: number;
  auteur: User;
  responsable?: User;
  adresse?: Adresse;
  structureVeterinaire: StructureVeterinaire;
  prestations?: Prestation[];
  animaux?: Animal[];
  paiements?: Paiement[];
  createDateTime: Date;
  updateDateTime?: Date;
}

export interface RdvDomicile extends Rdv {
  adresse: Adresse;
  dureeRdv?: number;
  prestations: Prestation[];
  createPaiementPourAnnulation?: boolean;
}

export interface RdvPlanning {
  id: string;
  typeRdv: TypeRdv;
  adresse?: Adresse;
  dureeRdv?: number;
  motif?: string;
  acte?: Acte;
  structureVeterinaire: StructureVeterinaire;
  dateRdv?: string;
  infosVerifieesAsv?: boolean;
  dateSouhaitee?: string;
  dateDemande?: string;
  dateCreneauRappel?: string;
  client?: Client;
  animaux?: Animal[];
  consultant?: User;
  rdvStep?: RdvState;
  previousStep?: RdvState;
  allCrFinalises?: boolean;
  interactions?: Interaction[];
  auteurId?: string;
  isAdresseVeto?: boolean;
}

export type CreationRdvPayloadType = Omit<RdvDomicile, 'id' | 'createDateTime'>;

export type CreationHorsRdvPayloadType = Omit<HorsRdv, 'id' | 'createDateTime' | 'auteur'>;

export type CreationRdvInternePayloadType = Omit<RdvInterne, 'id' | 'createDateTime'>;

export interface Client {
  id: string;
  adresse?: Adresse;
  mail: string;
  mail2?: string;
  useMail2?: boolean;
  nom?: string;
  nom2?: string;
  prenom?: string;
  prenom2?: string;
  role?: UserRole;
  telephone?: string;
  telephone2?: string;
  username?: string;
  detail?: string;
  nouveauClient?: boolean;
}

export interface CreationClientPayload {
  adresse: Adresse;
  mail: string;
  nom: string;
  prenom: string;
  role?: UserRole;
  telephone: string;
  mail2?: string;
  nom2?: string;
  prenom2?: string;
  telephone2?: string;
}

export interface Prestation {
  id?: string;
  consultant: User;
  datePrestation?: Date;
  animal?: Animal;
  factures?: Facture[];
  compteRendu?: CompteRendu;
  ordonnances?: Ordonnance[];
  clientId: string;
  typeRdv: TypeRdv;
  commentaireInterne?: string;
  facturations?: Facturation[];
  consentements?: Consentement[];
  createDateTime?: Date;
  updateDateTime?: Date;
  titre?: string;
}

export interface Facturation {
  id?: string;
  idx?: number;
  idFacture?: string;
  idsProduitsPrescritsSupprimes?: string[];
  idDevis?: string[];
  montant?: number;
  etat: EtatFacturationEnum;
  produitsDelivres?: ProduitDelivre[];
  actesRealises?: ActeRealise[];
  createDateTime?: Date;
  updateDateTime?: Date;
}

export interface Devis {
  id?: string;
  numDevis?: string;
  idFacturation?: string;
  idUser?: string;
  idClient?: string;
  idStructure?: string;
  idAnimal?: string;
  idRdvDomicile?: string;
  idPrestation?: string;
  etat: EtatDevisEnum;
  produitsDelivres?: ProduitDelivre[];
  actesRealises?: ActeRealise[];
  devis?: Blob;
  montant?: number;
  dateDevis?: Date;
}

export interface Facture {
  id?: string;
  idx?: number;
  numFacture?: string;
  montant?: number;
  evolizId?: number;
  dateFacture?: Date;
  etat?: EtatFactureEnum;
  itemList?: FactureItem[];
}

export interface FactureItem {
  id?: string;
  nom: string;
  reference?: string;
  description?: string;
  tarifHT?: number;
  tva: number;
  remisePourcent?: number;
  quantite: number;
}

export enum EtatFactureEnum {
  TERMINEE = 'TERMINEE',
  FINALISEE = 'FINALISEE',
  PAYEE = 'PAYEE',
  CREEE = 'CREEE',
  ANNULEE = 'ANNULEE',
}

export enum EtatFacturationEnum {
  EN_COURS = 'EN_COURS',
  FINALISEE = 'FINALISEE',
  TERMINEE = 'TERMINEE',
  PAYEE = 'PAYEE',
}

export enum EtatDevisEnum {
  EN_COURS = 'EN_COURS',
  FINALISE = 'FINALISE',
  REFUSE = 'REFUSE',
  FACTURE = 'FACTURE',
  TERMINE = 'TERMINE',
}

export interface VerifRdvByAsv {
  dateSouhaitee: string;
  dateConsultation: string; // pour les vétos
  motif: string;
  dureeRdv?: number;
  commentaire: string;
  client: Client;
  idStructureVeterinaire: string;
  idActe: string;
  idConsultant?: string;
  animaux: Animal[];
  adresseRdv: Adresse;
  rdvStep: RdvState;
  motifAnnulation?: string;
  libelleMotifAnnulation?: string;
  titre?: string;
  createPaiementPourAnnulation?: boolean;
}
