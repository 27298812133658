import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Page } from '../../../../models/page.model';
import { Browser } from 'leaflet';
import { Observable, Subject } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import mobile = Browser.mobile;

export interface InfiniteScrollRefreshOptions {
  deleteOld?: boolean;
  scrollTop?: boolean;
}

@Component({
  selector: 'app-infinite-scroll',
  templateUrl: './infinite-scroll.component.html',
  styleUrls: ['./infinite-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfiniteScrollComponent<T> implements OnInit, OnDestroy {
  items: T[] = [];
  private nbTotalPage?: number;

  @Input() itemTemplate!: TemplateRef<any>;
  @Input() itemsPerPage!: number; // doit être supérieur à la taille du viewport pour pouvoir déclencher le scroll
  @Input() sortFn?: (i1: T, i2: T) => number; // si sortFn renseigné on ne regarde pas orderBy
  @Input() orderBy?: { value: string | string[]; order?: 'asc' | 'desc' };

  @Input() itemSizePx!: number;
  @Input() itemSizePxMobile!: number;
  @Input() nextPageCallBack!: (page: number, offset?: number) => Observable<Page<T>>;
  @Input() trackByFn: (index: number, item: T) => any = (index, item) => (item as any)?.id;

  @ViewChild('viewport') viewport!: CdkVirtualScrollViewport;

  protected readonly mobile = mobile;
  itemPages?: Page<T>[];

  currentPageReqArr = new Int32Array([0, 0, 0]); // page, offset, isLoading

  destroy$: Subject<void> = new Subject();

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  /**
   * Pour forcer le scroll top
   */
  scrollToTop(): void {
    if (this.viewport) {
      this.viewport.scrollToOffset(0); // Scroll vers le haut
    }
  }

  addItemIfNotExists(item: T, identifier?: (obj: T) => any): void {
    let identifierFn = identifier;
    if (!identifier) {
      identifierFn = (i: T) => (i as any).id;
    }
    if (!this.items.some(i => identifierFn!(i) === identifierFn!(item))) {
      this.items.unshift(item);
    }
  }

  forceRefresh(page: number, offset?: number, options?: InfiniteScrollRefreshOptions): void {
    Atomics.store(this.currentPageReqArr, 2, 1); //isloading
    if (options?.deleteOld) {
      Atomics.store(this.currentPageReqArr, 0, page);
      Atomics.store(this.currentPageReqArr, 1, offset || 0);
    }
    this.nextPageCallBack(page, offset).subscribe(page => {
      this.addPageToItems(page, options);
    });
  }

  onScrolledIndexChange(index: number): void {
    if (
      (!this.items.length || (this.items.length && index * this.itemsPerPage >= this.items!.length - 1)) &&
      (!this.nbTotalPage || Atomics.load(this.currentPageReqArr, 0) < this.nbTotalPage) &&
      Atomics.load(this.currentPageReqArr, 2) !== 1
    ) {
      if (this.items.length) {
        Atomics.add(this.currentPageReqArr, 0, 1);
        Atomics.add(this.currentPageReqArr, 1, this.itemsPerPage!);
      }
      Atomics.store(this.currentPageReqArr, 2, 1); //isloading
      this.nextPageCallBack(Atomics.load(this.currentPageReqArr, 0), Atomics.load(this.currentPageReqArr, 1)).subscribe(page => {
        this.addPageToItems(page);
      });
    }
  }

  private addPageToItems(page: Page<T>, options?: InfiniteScrollRefreshOptions): void {
    if (!this.itemPages?.length) {
      this.itemPages = [page];
    } else {
      if (options?.deleteOld) {
        this.itemPages = [page];
      } else {
        const existingPageIndex = this.itemPages.findIndex(p => p.pageNumber === page.pageNumber);

        if (existingPageIndex !== -1) {
          this.itemPages[existingPageIndex] = page;
        } else {
          this.itemPages = [...this.itemPages, page].sort((a, b) => a.pageNumber - b.pageNumber);
        }
      }
    }
    this.items = this.itemPages.flatMap(p => p.elements);

    this.nbTotalPage = page.nbTotalPage;
    Atomics.store(this.currentPageReqArr, 2, 0); //isloading

    if (options?.scrollTop) {
      this.scrollToTop();
    }

    this.changeDetectorRef.markForCheck();
  }

  ngOnInit() {
    if (this.items.length === 0) {
      this.onScrolledIndexChange(0);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
