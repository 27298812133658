<ng-container *ngIf="consultation.prestations?.length; else noPresta">
  <ng-container *ngFor="let prestation of consultation.prestations">
    <ng-container *ngIf="idAnimalFilter ? prestation.animal?.id === idAnimalFilter : true">
      <ion-accordion>
        <ion-item
          slot="header"
          color="blue200"
          lines="none"
          class="consultation-header"
          [ngClass]="consultation.rdvStep"
          [tooltip]="consultation.rdvStep | rdvStep"
          [position]="position.LEFT"
        >
          <ion-label color="blue600" class="ion-text-wrap"
            ><h2>
              {{ (consultation.dateConsultation ? consultation.dateConsultation : consultation.dateSouhaitee) | date: 'mediumDate' }}
              {{ prestation.titre || prestation.compteRendu?.titre || consultation.titre || consultation.acte?.libelle }}
            </h2>
            <p *ngIf="!idAnimalFilter && prestation.animal?.nom">{{ prestation.animal?.nom | titlecase }}</p></ion-label
          >
        </ion-item>
        <div slot="content">
          <app-prestation-content *ngIf="user" [user]="user" [presta]="prestation" [mail]="consultation.client.mail"></app-prestation-content>
          <ion-item button (click)="goToConsultation(consultation.id)" detail lines="none">
            <ion-label class="ion-text-wrap">
              <ion-text color="grey900">
                <h3>
                  <b>
                    {{ consultation.acte ? consultation.acte.libelle : 'Type non renseigné' }}
                  </b>
                </h3>
                <p><b>Motif</b> : {{ consultation.motif || 'Aucun' }}</p>
                <p><b>Avec :</b> {{ consultation.consultantAssigne ? 'Dr ' + consultation.consultantAssigne.nom : 'Aucun consultant enregistré' }}</p>
              </ion-text>
            </ion-label>
          </ion-item>
        </div>
      </ion-accordion>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #noPresta>
  <ion-accordion>
    <ion-item
      slot="header"
      color="blue200"
      lines="none"
      class="consultation-header"
      [ngClass]="consultation.rdvStep"
      [tooltip]="consultation.rdvStep | rdvStep"
      [position]="position.LEFT"
    >
      <ion-label color="blue600" class="ion-text-wrap"
        ><h2>
          {{ (consultation.dateConsultation ? consultation.dateConsultation : consultation.dateSouhaitee) | date: 'mediumDate' }}
          {{ consultation.titre ? consultation.titre : consultation.acte?.libelle }}
        </h2>
        <p *ngFor="let animal of consultation.animaux">{{ animal?.nom | titlecase }}</p></ion-label
      >
    </ion-item>
    <div slot="content">
      <ion-item button (click)="goToConsultation(consultation.id)" detail lines="none">
        <ion-label class="ion-text-wrap">
          <ion-text color="grey900">
            <h3>
              <b>
                {{ consultation.acte ? consultation.acte.libelle : 'Type non renseigné' }}
              </b>
            </h3>
            <p><b>Motif</b> : {{ consultation.motif || 'Aucun' }}</p>
            <p><b>Avec :</b> {{ consultation.consultantAssigne ? 'Dr ' + consultation.consultantAssigne.nom : 'Aucun consultant enregistré' }}</p>
          </ion-text>
        </ion-label>
      </ion-item>
    </div>
  </ion-accordion>
</ng-template>
