import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AgePipe } from './components/custom-pipe/age.pipe';
import { DateRdvPipe } from './components/custom-pipe/dateRdv.pipe';
import { PoidsComponent } from './components/courbe-poids/poids.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { OrderByPipe } from './components/custom-pipe/orderByAz.pipe';
import { RdvStepPipe } from './components/custom-pipe/rdvStep.pipe';
import { DefaultValuePipe } from './components/custom-pipe/defaultValue.pipe';
import { JourPipe } from './components/custom-pipe/jour.pipe';
import { EditAdresseComponent } from './components/edit-adresse/edit-adresse.component';
import { ChangeAnimalComponent } from './components/change-animal/change-animal.component';
import { SearchableSelectComponent } from './components/search/searchable-select.component';
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { ConsultationListComponent } from './components/consultation-list/consultation-list.component';
import { TooltipModule } from './components/tooltip/tooltip.module';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { RangePipe } from './components/custom-pipe/range.pipe';
import { StockSourcePipe } from './components/custom-pipe/stock-source.pipe';
import { CmdStatutPipe } from './components/custom-pipe/commandeStatut.pipe';
import { FrenchNumberComponent } from './components/french-number/french-number.component';
import { ExportRdvComponent } from './components/export-rdv/export-rdv.component';
import { FrenchNumberPipe } from './components/french-number/french-number.pipe';
import { SexePipe } from './components/custom-pipe/sexe.pipe';
import { EtatDevisPipe } from './components/custom-pipe/etatDevis.pipe';
import { SharedService } from './services/shared.service';
import { HistoriqueAnimalComponent } from './components/historique-animal/historique-animal.component';
import { DateComparePipe } from './components/custom-pipe/date-compare.pipe';
import { UploadAnimalFileComponent } from './components/upload-animal-file/upload-animal-file.component';
import { NgxEditorModule } from 'ngx-editor';
import { StandaloneCrComponent } from './components/standalone-cr/standalone-cr.component';
import { PrestationComponent } from './prestation/prestation.component';
import { PrestationContentComponent } from './prestation/prestation-content/prestation-content.component';
import { ConsultationComponent } from './components/consultation-list/consultation/consultation.component';
import { StandaloneCrViewComponent } from './components/standalone-cr/view/standalone-cr-view/standalone-cr-view.component';
import { TrigrammePipe } from './components/custom-pipe/trigramme.pipe';
import { TypeRdvInterneStrPipe } from './components/custom-pipe/typeRdvInterneStr.pipe';
import { TypeRappelPipe } from './components/custom-pipe/typeRappel.pipe';
import { MarkdownPipe } from './components/custom-pipe/markdown.pipe';
import { InteractionComponent } from './components/interactions/interaction.component';
import { ExportDossierMedicalComponent } from '../fiche-client/components/export-dossier-medical/export-dossier-medical.component';
import { FacturationComponent } from './components/facturation/facturation.component';
import { ProduitRenouvelableComponent } from './components/produit-renouvelable/produit-renouvelable.component';
import { TypeHorsRdvStrPipe } from './components/custom-pipe/typeHorsRdvStr.pipe';
import { ReglementComponent } from './components/reglement/reglement.component';
import { HorsRdvComponent } from './components/consultation-list/hors-rdv/hors-rdv.component';
import { HorsRdvStepPipe } from './components/custom-pipe/horsRdvStep.pipe';
import { ModalCrTextComponent } from '../fiche-client/components/export-dossier-medical/modal-cr-text/modal-cr-text.component';
import { OrdonnanceComponent } from './components/ordonnance/ordonnance.component';
import { InfiniteScrollComponent } from './components/infinite-scroll/infinite-scroll.component';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';

const components = [
  PoidsComponent,
  EditAdresseComponent,
  ChangeAnimalComponent,
  HistoriqueAnimalComponent,
  FacturationComponent,
  OrdonnanceComponent,
  SearchableSelectComponent,
  ConsultationListComponent,
  PdfViewerComponent,
  FrenchNumberComponent,
  ExportRdvComponent,
  UploadAnimalFileComponent,
  StandaloneCrComponent,
  PrestationComponent,
  PrestationContentComponent,
  ConsultationComponent,
  HorsRdvComponent,
  StandaloneCrViewComponent,
  InteractionComponent,
  ProduitRenouvelableComponent,
  ReglementComponent,
  ExportDossierMedicalComponent,
  ModalCrTextComponent,
];

@NgModule({
  declarations: [
    ...components,
    AgePipe,
    DateRdvPipe,
    OrderByPipe,
    RdvStepPipe,
    TypeRappelPipe,
    EtatDevisPipe,
    DateComparePipe,
    HorsRdvStepPipe,
    SexePipe,
    JourPipe,
    MarkdownPipe,
    DefaultValuePipe,
    TypeHorsRdvStrPipe,
    RangePipe,
    StockSourcePipe,
    TrigrammePipe,
    CmdStatutPipe,
    TypeRdvInterneStrPipe,
    FrenchNumberPipe,
    InfiniteScrollComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ScrollingModule,
    ReactiveFormsModule,
    RouterModule,
    TooltipModule,
    CdkFixedSizeVirtualScroll,
    ScrollingModule,
    ExperimentalScrollingModule,
    CdkVirtualScrollViewport,
    CdkVirtualForOf,
    NgxExtendedPdfViewerModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    NgxEditorModule,
  ],
  providers: [DatePipe, SharedService],
  exports: [
    ...components,
    AgePipe,
    DateRdvPipe,
    OrderByPipe,
    RdvStepPipe,
    HorsRdvComponent,
    DateComparePipe,
    HorsRdvStepPipe,
    EtatDevisPipe,
    SexePipe,
    JourPipe,
    DefaultValuePipe,
    RangePipe,
    MarkdownPipe,
    TrigrammePipe,
    TypeRappelPipe,
    StockSourcePipe,
    CmdStatutPipe,
    FrenchNumberPipe,
    TypeRdvInterneStrPipe,
    TypeHorsRdvStrPipe,
    InfiniteScrollComponent,
  ],
})
export class SharedModule {}
