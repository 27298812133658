import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalController, ToastController } from '@ionic/angular';
import { LogService } from '@core/services/log/log.service';
import { CompteRendu, FileCR, FileCRTypeEnum } from '../../../../models/compte-rendu.model';
import { Prestation, RdvDomicile } from '../../../../models/rdv.model';
import { DossierMedicalRequest } from '../../../../models/export-dossier-medical.model';
import { CompteRenduService } from '../../../rdv/wizard/services/compte-rendu.service';
import { ModalCrTextComponent } from './modal-cr-text/modal-cr-text.component';

@Component({
  selector: 'envoi-facture',
  templateUrl: 'export-dossier-medical.component.html',
  styleUrls: ['export-dossier-medical.component.scss'],
})
export class ExportDossierMedicalComponent implements OnDestroy, OnInit {
  private readonly destroy$: Subject<void>;
  @Input() email!: string;
  @Input() historique?: (CompteRendu | RdvDomicile)[];
  @Input() idAnimal!: string;
  @Input() title!: string;
  loadingSend = false;
  protected crs: { selected: boolean; cr: CompteRendu; filesWrapped: { selected: boolean; file: FileCR }[] }[] = [];
  protected destinaire: string = 'RETOUR_CLINIQUE';
  protected clientEmail: string = '';

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly toastController: ToastController,
    private readonly compteRenduService: CompteRenduService,
    private readonly logService: LogService,
  ) {
    this.destroy$ = new Subject<void>();
  }

  ngOnInit() {
    this.historique?.forEach(crOrRdv => {
      if (this.isCompteRendu(crOrRdv)) {
        let filesWrapped: { selected: boolean; file: FileCR }[] = [];
        if (crOrRdv.files) {
          crOrRdv.files.forEach(file => {
            filesWrapped.push({ selected: false, file: file });
          });
        }
        this.crs.push({ selected: false, cr: crOrRdv, filesWrapped: filesWrapped });
      } else {
        crOrRdv.prestations
          .filter(p => p.animal?.id === this.idAnimal)
          .forEach(prestation => {
            const cr = prestation.compteRendu;
            if (cr) {
              cr.titre = this.getTitre(crOrRdv, prestation);
              if (crOrRdv.dateConsultation) {
                cr.dateCompteRendu = new Date(crOrRdv.dateConsultation);
              }
              let filesWrapped: { selected: boolean; file: FileCR }[] = [];
              if (cr.files) {
                cr.files.forEach(file => {
                  filesWrapped.push({ selected: false, file: file });
                });
              }
              this.crs.push({ selected: false, cr: cr, filesWrapped: filesWrapped });
            }
          });
      }
    });
  }

  async openModal(text: string | undefined) {
    console.log('OPEN');
    const modal = await this.modalCtrl.create({
      component: ModalCrTextComponent,
      cssClass: 'cr-text-modal',
      componentProps: {
        htmlContent: text,
      },
    });

    return await modal.present();
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  send() {
    var compteRendus = this.crs.filter(c => c.selected);
    let header = '';
    let message = '';
    if (compteRendus.length == 0) {
      header = 'Aucun compte rendu sélectionné';
      message = 'Vous devez sélectionner au moins un compte rendu pour envoyer le dossier médical';
    }
    if (this.clientEmail === '') {
      header = 'Aucun email renseigné';
      message = 'Vous devez renseigner un email pour envoyer le dossier médical';
    }
    if (header !== '') {
      this.toastController
        .create({
          header: header,
          duration: 5000,
          message: message,
          position: 'bottom',
          color: 'danger',
          icon: 'warning',
        })
        .then(toast => toast.present());
    } else {
      this.loadingSend = true;
      const request: DossierMedicalRequest = {
        mailDestinataire: this.clientEmail,
        destinataireType: this.destinaire,
        animalId: this.idAnimal,
        compteRenduIds: compteRendus.map(c => c.cr.id),
        fileCrIds: compteRendus.flatMap(c => c.filesWrapped.filter(f => f.selected).map(f => f.file.id)),
      };
      this.compteRenduService.sendDossierMedical(request).subscribe(() => {
        this.loadingSend = false;
        this.toastController
          .create({
            header: 'Dossier médical envoyé',
            duration: 5000,
            message: 'Un mail a été envoyé à : ' + this.email,
            position: 'bottom',
          })
          .then(toast => toast.present());
      });
    }
  }

  download() {
    var compteRendus = this.crs.filter(c => c.selected);
    let header = '';
    let message = '';
    if (compteRendus.length == 0) {
      header = 'Aucun compte rendu sélectionné';
      message = 'Vous devez sélectionner au moins un compte rendu pour envoyer le dossier médical';
    }
    if (header !== '') {
      this.toastController
        .create({
          header: header,
          duration: 5000,
          message: message,
          position: 'bottom',
          color: 'danger',
          icon: 'warning',
        })
        .then(toast => toast.present());
    } else {
      this.loadingSend = true;
      const request: DossierMedicalRequest = {
        mailDestinataire: this.clientEmail,
        destinataireType: this.destinaire,
        animalId: this.idAnimal,
        compteRenduIds: compteRendus.map(c => c.cr.id),
        fileCrIds: compteRendus.flatMap(c => c.filesWrapped.filter(f => f.selected).map(f => f.file.id)),
      };
      this.compteRenduService.getDossierMedical(request).subscribe(pdf => {
        const blob = new Blob([pdf], { type: 'application/pdf' });
        const fileURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = 'dossier_medical.pdf';
        link.click();
        this.loadingSend = false;
      });
    }
  }

  protected crCheck($event: any, cr: CompteRendu) {
    if (!$event.target.checked) {
      this.crs.filter(c => c.cr.id === cr.id)[0].filesWrapped.forEach(f => (f.selected = false));
    }
  }

  protected fileCheck($event: any, cr: CompteRendu) {
    if ($event.target.checked) {
      this.crs.filter(c => c.cr.id === cr.id)[0].selected = true;
    }
  }

  changeDestinaire() {
    if (this.destinaire === 'CLIENT') {
      this.clientEmail = this.email;
    } else {
      this.clientEmail = '';
    }
  }

  // setMail($event: any) {
  //   this.email = $event.target.value;
  // }

  isCompteRendu(item: CompteRendu | RdvDomicile): item is CompteRendu {
    return (item as CompteRendu).dateCompteRendu !== undefined;
  }

  getTitre(rdv: RdvDomicile, prestation: Prestation): string | undefined {
    return prestation.titre || prestation.compteRendu?.titre || rdv.titre || rdv.acte?.libelle;
  }

  protected readonly FileCRTypeEnum = FileCRTypeEnum;
}
