import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { Animal } from 'app/models/animal.model';
import { Client, RdvDomicile } from 'app/models/rdv.model';
import { UserService } from '@core/services/user/user.service';
import { ChannelRappel, Rappel, TypeRappel, TypeVaccin } from '../../../../../models/user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { addDays } from 'date-fns';

@Component({
  selector: 'vaccin-modal-component',
  templateUrl: './vaccin-modal.component.html',
  styleUrls: ['./vaccin-modal.component.scss'],
})
export class VaccinModalComponent implements OnInit {
  vaccinForm?: FormGroup;
  animal!: Animal;
  client!: Client;
  rdv?: RdvDomicile;
  showNoRappel = true;
  typesVaccin: string[] = Object.values(TypeVaccin);

  existingRappel?: Rappel;

  constructor(
    private readonly modalCtrl: ModalController,
    protected readonly userSevice: UserService,
    private readonly fb: FormBuilder,
    private readonly datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    if (this.existingRappel) {
      let dateRappel: Date;
      if (this.existingRappel.dateRappel) {
        dateRappel = addDays(new Date(this.existingRappel.dateRappel), 21);
      } else {
        const dateDuJour = new Date();
        dateRappel = new Date(dateDuJour.setMonth(dateDuJour.getMonth() + 1));
      }
      this.vaccinForm = this.fb.group({
        dateRappel: [this.datePipe.transform(dateRappel, 'yyyy-MM-dd'), [Validators.required]],
        typeVaccin: [this.existingRappel.description!.split(':')[0].trim(), [Validators.required]],
        delais: [null],
      });
    } else {
      const dateDuJour = new Date();
      const dateRappel: Date = new Date(dateDuJour.setMonth(dateDuJour.getMonth() + 1));
      this.vaccinForm = this.fb.group({
        dateRappel: [this.datePipe.transform(dateRappel, 'yyyy-MM-dd'), [Validators.required]],
        typeVaccin: [null, [Validators.required]],
        delais: [1, [Validators.required]],
      });
    }
  }

  confirm() {
    let rappel: Rappel;
    if (this.existingRappel) {
      rappel = {
        ...this.existingRappel,
        dateRappel: this.vaccinForm!.get('dateRappel')!.value,
        description: this.vaccinForm!.get('typeVaccin')!.value,
      };
    } else {
      rappel = {
        type: TypeRappel.VACCIN,
        channels: [ChannelRappel.MAIL],
        dateRappel: this.vaccinForm!.get('dateRappel')!.value,
        userId: this.client.id,
        animalId: this.animal.id,
        contextId: this.rdv?.id,
        description: this.vaccinForm!.get('typeVaccin')!.value,
      };
    }
    this.userSevice.saveRappel(rappel).subscribe();

    this.modalCtrl.dismiss(rappel);
  }

  annuler() {
    this.modalCtrl.dismiss();
  }

  pasDeRappel() {
    this.modalCtrl.dismiss({ data: '' });
  }

  changeDelais() {
    let dateDuJour = new Date();
    let dateRappel: Date = new Date(dateDuJour.setMonth(dateDuJour.getMonth() + this.vaccinForm!.get('delais')!.value));

    this.vaccinForm!.get('dateRappel')!.setValue(this.datePipe.transform(dateRappel, 'yyyy-MM-dd'));
  }
}
