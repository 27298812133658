import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { selectLoadingProduits } from '@core/store/selector/admin.selector';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, skipWhile, Subject, takeUntil } from 'rxjs';
import { Produit, SearchProduitRequest } from 'app/models/produit.model';
import { AdminService } from '../../../services/admin.service';
import { Action, Context, TypeLog } from '../../../../../models/log.model';
import { LogService } from '@core/services/log/log.service';
import { NavController } from '@ionic/angular';
import { User, UserRole } from '../../../../../models/user.model';
import { selectUser } from '@core/store/selector/session.selectors';
import { tap } from 'rxjs/operators';
import { InfiniteScrollComponent, InfiniteScrollRefreshOptions } from '../../../../shared/components/infinite-scroll/infinite-scroll.component';
import { Conversation } from '../../../../../models/interaction.model';

@Component({
  selector: 'tarif-produits-page',
  templateUrl: 'tarif-produits.page.html',
  styleUrls: ['tarif-produits.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TarifProduitsPage implements OnInit, OnDestroy {
  loadingProduit$: Observable<boolean | undefined> = this.store.select(selectLoadingProduits).pipe(distinctUntilChanged());
  searchRequest: SearchProduitRequest = { page: 0, size: 50, offset: 0, visible: true };
  filterChangeSubject$: Subject<SearchProduitRequest> = new Subject();
  destroy$: Subject<void> = new Subject();

  user?: User;
  @ViewChild('scrollComponent') scrollComponent?: InfiniteScrollComponent<Conversation>;

  constructor(
    private readonly store: Store,
    private readonly adminService: AdminService,
    private readonly logService: LogService,
    private readonly navCtrl: NavController,
  ) {}

  ngOnInit() {
    this.logService.log(Context.ADMIN_TARIF_PRODUITS, Action.CONSULTATION_LIST, TypeLog.INFO, {});
    this.store
      .select(selectUser)
      .pipe(
        skipWhile(user => user === null),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
        tap(user => {
          this.user = user;
        }),
      )
      .subscribe();
  }

  searchProduits(ev: any) {
    const value = ev.target!.value;
    if (value?.length > 2) {
      this.resetSearchRequest();
      this.searchRequest.request = value;
      this.refreshList({ deleteOld: true, scrollTop: true });
    } else {
      if (this.searchRequest.request) {
        this.resetSearchRequest();

        this.searchRequest.request = undefined;
        this.filterChangeSubject$.next(this.searchRequest);
      }
    }
  }

  private resetSearchRequest() {
    this.searchRequest.page = 0;
    this.searchRequest.offset = 0;
  }

  private refreshList(options?: InfiniteScrollRefreshOptions) {
    if (this.scrollComponent) {
      this.scrollComponent.forceRefresh(0, 0, options);
    }
  }

  toggleProduitsVisibles(checked: boolean) {
    this.resetSearchRequest();
    this.searchRequest.visible = checked ? checked : undefined;
    this.refreshList({ deleteOld: true, scrollTop: true });
  }

  toggleProduitsSansUvc(checked: boolean) {
    this.resetSearchRequest();

    this.searchRequest.sansUVC = checked ? checked : undefined;
    this.refreshList({ deleteOld: true, scrollTop: true });
  }

  toggleProduitsSansTarif(checked: boolean) {
    this.resetSearchRequest();

    this.searchRequest.sansTarif = checked ? checked : undefined;
    this.refreshList({ deleteOld: true, scrollTop: true });
  }

  openDetail(produit: Produit) {
    this.logService.log(Context.ADMIN_TARIF_PRODUITS, Action.CONSULTATION, TypeLog.INFO, produit);
    this.adminService.openDetailTarif(produit, 'produit', 'view', this.user!).then(ev => {
      if (ev.data) {
        this.filterChangeSubject$.next(this.searchRequest);
      }
    });
  }

  openEdit(produit: Produit) {
    this.logService.log(Context.ADMIN_TARIF_PRODUITS, Action.ASK_MODIFICATION, TypeLog.INFO, produit);
    this.adminService.openDetailTarif(produit, 'produit', 'edit', this.user!).then(ev => {
      if (ev.data) {
        this.filterChangeSubject$.next(this.searchRequest);
      }
    });
  }

  getNextPage(page: number, offset?: number) {
    return this.adminService.searchProduits({ ...this.searchRequest, page, offset });
  }

  backTarif() {
    if (this.user?.role === UserRole.ROLE_VETERINAIRE) {
      this.navCtrl.navigateBack(['tarifs'], { queryParamsHandling: 'merge' });
    } else {
      this.navCtrl.navigateBack(['zone-admin', 'tarifs'], { queryParamsHandling: 'merge' });
    }
  }

  createProduit() {
    this.logService.log(Context.ADMIN_TARIF_ACTES, Action.ASK_CREATION, TypeLog.INFO, {});
    this.adminService.openDetailTarif({}, 'produit', 'edit', this.user!);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected readonly UserRole = UserRole;
}
