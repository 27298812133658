import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { User } from '../../../../models/user.model';
import { UserService } from '@core/services/user/user.service';
import { ToastController } from '@ionic/angular';
import { from, map, switchMap } from 'rxjs';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.page.html',
  styleUrls: ['./forgot-password.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordPage {
  resetPasswordForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    user: [null],
  });

  users?: User[];
  isSubmitting: boolean = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly userService: UserService,
    private readonly toastController: ToastController,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  submit() {
    this.isSubmitting = true;
    const email = this.resetPasswordForm.value.email?.toLowerCase();
    if (email && !this.resetPasswordForm.value.user) {
      this.userService
        .forgotPassword(email)
        .pipe(
          switchMap(() =>
            from(
              this.toastController.create({
                header: 'Mail envoyé',
                duration: 5000,
                message: `Un lien pour réinitialiser votre mot de passe vous a été envoyé par mail`,
                position: 'bottom',
              }),
            ),
          ),
          map(toast => toast.present()),
        )
        .subscribe(() => {
          this.isSubmitting = false;
          this.changeDetectorRef.markForCheck();
        });
    } else if (this.resetPasswordForm.value.user) {
      this.userService
        // @ts-ignore
        .forgotPassword(this.resetPasswordForm.value.user.mail)
        .pipe(
          switchMap(() =>
            from(
              this.toastController.create({
                header: 'Mail envoyé',
                duration: 5000,
                message: `Un lien pour réinitialiser votre mot de passe vous a été envoyé par mail`,
                position: 'bottom',
              }),
            ),
          ),
          map(toast => toast.present()),
        )
        .subscribe(() => {
          this.isSubmitting = false;
          this.changeDetectorRef.markForCheck();
        });
    }
  }
}
