<ion-header class="ion-no-border ion-no-shadow">
  <ion-toolbar color="blue100">
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="backTarif()">
        <ion-icon slot="icon-only" name="close-circle-outline" color="blue900"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content [scrollY]="false" color="blue100">
  <ion-grid fixed>
    <ion-row>
      <ion-col class="first-col">
        <div class="welcome">
          <div class="center">
            <div class="with-sidebar-inverted">
              <div class="">
                <h2>Liste des produits</h2>
              </div>
              <div class="line"></div>
            </div>
          </div>
        </div>
        <div class="btn-list" *ngIf="user?.role === UserRole.ROLE_ADMIN">
          <ion-chip (click)="createProduit()">
            <ion-label>Créer un produit</ion-label>
            <ion-icon name="add-circle-outline"></ion-icon>
          </ion-chip>
        </div>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size-lg="8" offset-lg="2" size-xs="12">
        <div class="search-produit">
          <ion-item lines="none" color="blue100">
            <ion-searchbar placeholder="Chercher un produit" name="search" (ionInput)="searchProduits($event)" mode="md"></ion-searchbar>
          </ion-item>
        </div>
        <div class="center" *ngIf="user?.role === UserRole.ROLE_ADMIN">
          <div class="btn-list">
            <ion-chip
              (click)="visibleCheckbox.checked = !visibleCheckbox.checked"
              (ionChange)="toggleProduitsVisibles(visibleCheckbox.checked)"
              [ngClass]="searchRequest.visible ? 'checked' : 'parametre-produit'"
            >
              <ion-label>Visibles</ion-label>
              <ion-checkbox name="visible" checked #visibleCheckbox (click)="visibleCheckbox.checked = !visibleCheckbox.checked" mode="md"></ion-checkbox>
            </ion-chip>
            <ion-chip
              (click)="sansUvcCheckbox.checked = !sansUvcCheckbox.checked"
              (ionChange)="toggleProduitsSansUvc(sansUvcCheckbox.checked)"
              [ngClass]="searchRequest.sansUVC ? 'checked' : 'parametre-produit'"
            >
              <ion-label>Sans UVC</ion-label>
              <ion-checkbox name="sansUvc" #sansUvcCheckbox (click)="sansUvcCheckbox.checked = !sansUvcCheckbox.checked" mode="md"></ion-checkbox>
            </ion-chip>
            <ion-chip
              (click)="sansTarifCheckbox.checked = !sansTarifCheckbox.checked"
              (ionChange)="toggleProduitsSansTarif(sansTarifCheckbox.checked)"
              [ngClass]="searchRequest.sansTarif ? 'checked' : 'parametre-produit'"
            >
              <ion-label>Sans tarif</ion-label>
              <ion-checkbox name="sansTarif" #sansTarifCheckbox (click)="sansTarifCheckbox.checked = !sansTarifCheckbox.checked" mode="md"></ion-checkbox>
            </ion-chip>
          </div>
        </div>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <ng-container *ngIf="loadingProduit$ | async">
          <div class="ion-text-center">
            <ion-spinner name="crescent" color="danger"></ion-spinner>
          </div>
        </ng-container>
        <app-infinite-scroll
          #scrollComponent
          [itemsPerPage]="searchRequest.size!"
          [orderBy]="{ value: 'nom' }"
          [itemSizePx]="63"
          [itemSizePxMobile]="103"
          [nextPageCallBack]="getNextPage.bind(this)"
          [itemTemplate]="produitTemplate"
        >
        </app-infinite-scroll>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ng-template #produitTemplate let-produit>
  <ion-item color="blue200" lines="none" [style.cursor]="'pointer'" detail (click)="openDetail(produit)" class="produit-item">
    <ion-label class="ion-text-wrap" color="grey900">
      <h3>
        <b>{{ produit.nom }}</b>
      </h3>
      <p>
        {{ produit.tarifVenteHT ? ((produit.tarifVenteHT * (100 + (produit.tva || 20))) / 100 | number: '1.0-2') + '€ TTC' : 'Sans tarif' }}
        {{ produit.tarifTotalMinHT ? '(total min : ' + ((produit.tarifTotalMinHT * (100 + (produit.tva || 20))) / 100 | number: '1.0-2') + '€ TTC)' : '' }}
        ({{ produit.unite || produit.packaging }})
        {{ ' - UVC : ' + (produit.uvc ? produit.uvc : '-') }}
        {{ user?.role === UserRole.ROLE_ADMIN ? ' - Coef : ' + produit.coefAchatVente : '' }}
      </p>
    </ion-label>
    <ion-button slot="start" (click)="openEdit(produit); $event.stopPropagation()" color="white" *ngIf="user?.role === UserRole.ROLE_ADMIN">
      <ion-icon slot="icon-only" name="create-outline" color="blue600"></ion-icon>
    </ion-button>
  </ion-item>
</ng-template>
